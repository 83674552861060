import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { useNewCustomerLoginStore } from 'screens/NewCustomer/store/useNewCustomerLoginStore';
import * as actions from './auth.action';
import * as calls from './auth.calls';
import {
  ApiAuthResponse,
  AuthActionTypesEnum,
  LoginPayloadWithCredentials,
  LoginRequestAction,
  LogoutActionRequest,
  MfaPayload,
  SetCurrentUserAction
} from './auth.types';
import { getUserData } from './auth.utils';
import { extractErrorAndShowToast } from '../../utils/helpers/extractErrorAndShowToast';
import { unprocessableEntity } from '../../utils/constants/api/statuses';
import history from '../../hashHistory';
import { routes } from '../../utils/constants/routes';
import {
  AUTHENTICATION_FAILED,
  NO_ACCOUNT_ASSOCIATED_FOR_CURRENT_AUTH_MODE,
  REDIRECT_NOT_ALLOWED,
  SAML_ACCOUNT_MAPPING_DEPROVISION_WITH_NO_OWNER,
  SAML_CONFIRM_MIGRATION,
  SAML_ERRORS,
  SOCIAL_CONFIRM_MIGRATION,
  USER_MFA_GENERAL_ERROR,
  USER_MFA_QUOTA_EXCEEDED,
  USER_MFA_REQUIRED
} from '../../utils/constants/api/codes';
import { errorsMap } from '../../utils/constants/api/errorsMap';
import i18n from '../../locale/i18n';
import { getUrlWithProtocol } from '../../utils';
import { handleErrorDisplay } from '../../utils/helpers/extractError';
import { showToast } from '../../components/Toast/showToast';
import AuthBroadcast from '../../services/broadcastChannels/AuthBroadcast/AuthBroadcast';
import { closeOktaSession } from '../../utils/oktaInstance';
import { Config } from '../../config';

const isProduction = process.env.NODE_ENV === 'production';

function* setCurrentUser(action: SetCurrentUserAction) {
  const userData = getUserData(action.payload);
  yield put(actions.loginSuccess(userData));
}

function* login(action: LoginRequestAction) {
  const { search } = history.location;
  const { callCsrfFlag, ...payload } = action.payload;
  const paramsFromSession = window.sessionStorage.getItem('queryParams'); // get saved query
  const loginType = window.sessionStorage.getItem('loginType');
  const convertUtmData = {};

  // params before redirects
  const searchParams = new URLSearchParams(paramsFromSession || search);
  const searchParamsAsObject = Object.fromEntries(searchParams);

  if (!Object.keys(searchParamsAsObject).some((key) => key.indexOf('utm_') !== -1)) {
    const cookiesData = Cookies.get('wtaFd04Tracking');
    if (cookiesData) {
      const utmData = JSON.parse(cookiesData).utm_data;
      Object.keys(utmData || {}).forEach((key) => {
        if (key.indexOf('converting_') !== -1) {
          convertUtmData[key.replace('converting', 'utm')] = utmData[key];
        }
      });
    }
  }

  try {
    if (searchParamsAsObject?.return_to && paramsFromSession) {
      window.sessionStorage.removeItem('queryParams');
    }

    const { data }: AxiosResponse<ApiAuthResponse> = yield call(calls.login, {
      origin: Config.IS_RELATIVE_API
        ? `${window.location.origin}/#`
        : `${new URL(Config.CURRENT_ORIGINS.SM).origin}/#`,
      ...searchParamsAsObject,
      ...convertUtmData,
      ...payload,
      auth_mode: loginType || ''
    });

    const isFirstSocialLogin = window.localStorage.getItem('isFirstSocialLogin') === 'true';

    if (data?.firstSocialLogin || isFirstSocialLogin) {
      const newCustomerLoginStore = useNewCustomerLoginStore.getState();
      newCustomerLoginStore.setIsFirstLogin(true);
    }

    if (data.url && isProduction) {
      window.location.href = data.url;
    } else if (searchParamsAsObject.redirectTo) {
      window.location.href = searchParamsAsObject.redirectTo;
    } else if (window.sessionStorage.getItem('locationEnvPath')) {
      window.location.pathname = window.sessionStorage.getItem('locationEnvPath');
      window.sessionStorage.removeItem('locationEnvPath');
      if (loginType === 'direct') {
        const userData = getUserData(data);
        yield put(actions.makeCsrfCall(userData));
      }
    } else {
      const userData = getUserData(data);
      AuthBroadcast.postMessage({
        type: 'login',
        payload: { id: userData.id }
      });

      if (!callCsrfFlag) {
        yield put(actions.loginSuccess(userData));
      } else {
        yield put(actions.makeCsrfCall(userData));
      }
    }
  } catch (e) {
    const errorResponse = e?.response;

    if (paramsFromSession) {
      window.sessionStorage.setItem('queryParams', paramsFromSession);
    }

    const errorCode = errorResponse?.data?.errors?.code;
    const errorStatus = errorResponse?.status;

    yield put(actions.setLoginErrorCode(errorStatus));

    if (errorCode === USER_MFA_REQUIRED) {
      const mfaParams = JSON.parse(`${errorResponse.data.errors.params}`);
      const authPayload = action.payload || {};

      yield put(
        actions.mfaLogin({
          ...mfaParams,
          ...authPayload,
          auth_mode: loginType || ''
        } as MfaPayload & LoginPayloadWithCredentials)
      );
    } else if (errorCode === USER_MFA_QUOTA_EXCEEDED) {
      closeOktaSession();
      showToast(i18n.t(errorsMap[errorCode].message));
    } else if (
      [
        NO_ACCOUNT_ASSOCIATED_FOR_CURRENT_AUTH_MODE,
        AUTHENTICATION_FAILED,
        REDIRECT_NOT_ALLOWED
      ].includes(errorCode)
    ) {
      if (['google', 'github', 'sso'].includes(loginType)) {
        closeOktaSession();
      }

      showToast(i18n.t(errorsMap[errorCode].message));
    } else if (errorStatus === unprocessableEntity) {
      // used for cases when the user needs to confirm migration
      if (errorCode === SOCIAL_CONFIRM_MIGRATION) history.push(routes.socialConfirmMigration);
      if (errorCode === SAML_CONFIRM_MIGRATION) history.push(routes.samlConfirmMigration);
    } else if (SAML_ERRORS.includes(errorCode)) {
      showToast({
        description:
          errorCode === SAML_ACCOUNT_MAPPING_DEPROVISION_WITH_NO_OWNER
            ? errorResponse?.data?.errors?.message
            : i18n.t(errorsMap[errorCode].message)
      });
    } else if (errorCode === USER_MFA_GENERAL_ERROR) {
      handleErrorDisplay(e);
    } else if (errorResponse) {
      extractErrorAndShowToast(e);
    }
  }
}

function* logout(action: LogoutActionRequest) {
  window.sessionStorage.setItem('logOutFlag', 'true'); // used to flag a logout request on login page

  try {
    const samlLogoutUrl = window.sessionStorage.getItem('samlLogoutUrl');
    window.sessionStorage.removeItem('samlLogoutUrl');
    window.localStorage.removeItem('lastActivityTime');

    yield call(calls.logout);
    AuthBroadcast.postMessage({
      type: 'logout'
    });
    yield put(actions.logoutSuccess());

    if (window.localStorage.getItem('firstDatabaseCongratulationsShown') === 'shown') {
      window.localStorage.setItem('firstDatabaseCongratulationsShown', 'true');
    }

    if (samlLogoutUrl) {
      window.location.href = getUrlWithProtocol(samlLogoutUrl);
    } else {
      action.payload.redirect && history.push(action.payload.redirect);
    }
  } catch (e) {
    if (e?.response) {
      extractErrorAndShowToast(e);
    }
  }
}

function* authSaga() {
  yield takeLatest(AuthActionTypesEnum.LOGOUT_REQUEST, logout);
  yield takeLatest(AuthActionTypesEnum.LOGIN_REQUEST, login);
  yield takeLatest(AuthActionTypesEnum.SET_CURRENT_USER, setCurrentUser);
}

export default authSaga;
